import {
  Domain, getEnumMeta, solidify, ModelType, ObjectType,
  PrimitiveProperty, ForeignKeyProperty, PrimaryKeyProperty,
  ModelCollectionNavigationProperty, ModelReferenceNavigationProperty,
  HiddenAreas, BehaviorFlags
} from 'coalesce-vue/lib/metadata'


const domain: Domain = { enums: {}, types: {}, services: {} }
export const AddonItemType = domain.enums.AddonItemType = {
  name: "AddonItemType",
  displayName: "Addon Item Type",
  type: "enum",
  ...getEnumMeta<"FixedPerAddon"|"FixedPerTicket"|"PercentPerTicket">([
  {
    value: 0,
    strValue: "FixedPerAddon",
    displayName: "Fixed price per Addon Item",
    description: "Items must be refunded/exchanged individually.",
  },
  {
    value: 1,
    strValue: "FixedPerTicket",
    displayName: "Fixed price per Admission Ticket",
    description: "When admission tickets are refunded/exchanged, items of this type are too.",
  },
  {
    value: 2,
    strValue: "PercentPerTicket",
    displayName: "Percentage of Admission Tickets Price",
    description: "When admission tickets are refunded/exchanged, items of this type are too.",
  },
  ]),
}
export const AddonStatus = domain.enums.AddonStatus = {
  name: "AddonStatus",
  displayName: "Addon Status",
  type: "enum",
  ...getEnumMeta<"Completed"|"Exchanged"|"Refunded">([
  {
    value: 0,
    strValue: "Completed",
    displayName: "Completed",
  },
  {
    value: 2,
    strValue: "Exchanged",
    displayName: "Exchanged",
  },
  {
    value: 3,
    strValue: "Refunded",
    displayName: "Refunded",
  },
  ]),
}
export const BoardingLabel = domain.enums.BoardingLabel = {
  name: "BoardingLabel",
  displayName: "Boarding Label",
  type: "enum",
  ...getEnumMeta<"Doors"|"Boarding"|"Arrive">([
  {
    value: 0,
    strValue: "Doors",
    displayName: "Doors open",
  },
  {
    value: 1,
    strValue: "Boarding",
    displayName: "Boarding begins",
  },
  {
    value: 2,
    strValue: "Arrive",
    displayName: "Arrive by",
  },
  ]),
}
export const DiscountType = domain.enums.DiscountType = {
  name: "DiscountType",
  displayName: "Discount Type",
  type: "enum",
  ...getEnumMeta<"PerPurchaseFlatRateDiscount"|"PerPurchasePercentageDiscount"|"PerTicketFlatRateDiscount"|"PerTicketPercentageDiscount">([
  {
    value: 0,
    strValue: "PerPurchaseFlatRateDiscount",
    displayName: "Per Purchase Flat Rate Discount",
  },
  {
    value: 1,
    strValue: "PerPurchasePercentageDiscount",
    displayName: "Per Purchase Percentage Discount",
  },
  {
    value: 2,
    strValue: "PerTicketFlatRateDiscount",
    displayName: "Per Ticket Flat Rate Discount",
  },
  {
    value: 3,
    strValue: "PerTicketPercentageDiscount",
    displayName: "Per Ticket Percentage Discount",
  },
  ]),
}
export const EmailType = domain.enums.EmailType = {
  name: "EmailType",
  displayName: "Email Type",
  type: "enum",
  ...getEnumMeta<"ImmediatelyAfterPurchase"|"WithinOneDay"|"ThreeHoursAfterTheEvent"|"OneHourAfterTheEvent"|"Confirmation"|"Cancellation">([
  {
    value: 0,
    strValue: "ImmediatelyAfterPurchase",
    displayName: "Immediately After Purchase",
  },
  {
    value: 1,
    strValue: "WithinOneDay",
    displayName: "Within One Day",
  },
  {
    value: 2,
    strValue: "ThreeHoursAfterTheEvent",
    displayName: "Three Hours After The Event",
  },
  {
    value: 3,
    strValue: "OneHourAfterTheEvent",
    displayName: "One Hour After The Event",
  },
  {
    value: 100,
    strValue: "Confirmation",
    displayName: "Confirmation",
  },
  {
    value: 101,
    strValue: "Cancellation",
    displayName: "Cancellation",
  },
  ]),
}
export const PaymentKind = domain.enums.PaymentKind = {
  name: "PaymentKind",
  displayName: "Payment Kind",
  type: "enum",
  ...getEnumMeta<"Payment"|"CreditCardPayment">([
  {
    value: 0,
    strValue: "Payment",
    displayName: "Payment",
  },
  {
    value: 1,
    strValue: "CreditCardPayment",
    displayName: "Credit Card Payment",
  },
  ]),
}
export const PurchaseStatus = domain.enums.PurchaseStatus = {
  name: "PurchaseStatus",
  displayName: "Purchase Status",
  type: "enum",
  ...getEnumMeta<"Started"|"Completed"|"Canceled"|"Expired">([
  {
    value: 0,
    strValue: "Started",
    displayName: "Started",
  },
  {
    value: 1,
    strValue: "Completed",
    displayName: "Completed",
  },
  {
    value: 2,
    strValue: "Canceled",
    displayName: "Canceled",
  },
  {
    value: 3,
    strValue: "Expired",
    displayName: "Expired",
  },
  ]),
}
export const PurchaseType = domain.enums.PurchaseType = {
  name: "PurchaseType",
  displayName: "Purchase Type",
  type: "enum",
  ...getEnumMeta<"Purchase"|"Exchange"|"Refund">([
  {
    value: 0,
    strValue: "Purchase",
    displayName: "Purchase",
  },
  {
    value: 1,
    strValue: "Exchange",
    displayName: "Exchange",
  },
  {
    value: 2,
    strValue: "Refund",
    displayName: "Refund",
  },
  ]),
}
export const RefundableState = domain.enums.RefundableState = {
  name: "RefundableState",
  displayName: "Refundable State",
  type: "enum",
  ...getEnumMeta<"NotOffered"|"Purchased"|"Declined">([
  {
    value: 0,
    strValue: "NotOffered",
    displayName: "Not Offered",
  },
  {
    value: 1,
    strValue: "Purchased",
    displayName: "Purchased",
  },
  {
    value: 2,
    strValue: "Declined",
    displayName: "Declined",
  },
  ]),
}
export const SeatMapAnnotationShape = domain.enums.SeatMapAnnotationShape = {
  name: "SeatMapAnnotationShape",
  displayName: "Seat Map Annotation Shape",
  type: "enum",
  ...getEnumMeta<"Rectangle"|"RoundedRectangle"|"Oval"|"None">([
  {
    value: 0,
    strValue: "Rectangle",
    displayName: "Rectangle",
  },
  {
    value: 1,
    strValue: "RoundedRectangle",
    displayName: "Rounded Rectangle",
  },
  {
    value: 2,
    strValue: "Oval",
    displayName: "Oval",
  },
  {
    value: 3,
    strValue: "None",
    displayName: "None",
  },
  ]),
}
export const TicketStatus = domain.enums.TicketStatus = {
  name: "TicketStatus",
  displayName: "Ticket Status",
  type: "enum",
  ...getEnumMeta<"Completed"|"Hold"|"Exchanged"|"Refunded">([
  {
    value: 0,
    strValue: "Completed",
    displayName: "Completed",
  },
  {
    value: 1,
    strValue: "Hold",
    displayName: "Hold",
  },
  {
    value: 2,
    strValue: "Exchanged",
    displayName: "Exchanged",
  },
  {
    value: 3,
    strValue: "Refunded",
    displayName: "Refunded",
  },
  ]),
}
export const EventDateDto = domain.types.EventDateDto = {
  name: "EventDateDto",
  displayName: "Event Date Dto",
  get displayProp() { return this.props.id }, 
  type: "model",
  controllerRoute: "EventDateDto",
  get keyProp() { return this.props.id }, 
  behaviorFlags: 0 as BehaviorFlags,
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "primaryKey",
      hidden: 3 as HiddenAreas,
    },
    eventStartDateTime: {
      name: "eventStartDateTime",
      displayName: "Event Start Date Time",
      type: "date",
      dateKind: "datetime",
      role: "value",
    },
    eventEndDateTime: {
      name: "eventEndDateTime",
      displayName: "Event End Date Time",
      type: "date",
      dateKind: "datetime",
      role: "value",
    },
    eventPublicPurchaseDatetime: {
      name: "eventPublicPurchaseDatetime",
      displayName: "Event Public Purchase Datetime",
      type: "date",
      dateKind: "datetime",
      role: "value",
    },
    eventStartDateTimeLocal: {
      name: "eventStartDateTimeLocal",
      displayName: "Event Start Date Time Local",
      type: "date",
      dateKind: "datetime",
      noOffset: true,
      role: "value",
      dontSerialize: true,
    },
    eventEndDateTimeLocal: {
      name: "eventEndDateTimeLocal",
      displayName: "Event End Date Time Local",
      type: "date",
      dateKind: "datetime",
      noOffset: true,
      role: "value",
      dontSerialize: true,
    },
    durationMinutes: {
      name: "durationMinutes",
      displayName: "Duration Minutes",
      type: "number",
      role: "value",
    },
    eventCapacity: {
      name: "eventCapacity",
      displayName: "Event Capacity",
      type: "number",
      role: "value",
    },
    cancelled: {
      name: "cancelled",
      displayName: "Cancelled",
      type: "boolean",
      role: "value",
    },
    eventId: {
      name: "eventId",
      displayName: "Event Id",
      type: "number",
      role: "value",
    },
    eventName: {
      name: "eventName",
      displayName: "Event Name",
      type: "string",
      role: "value",
    },
    eventShortDescription: {
      name: "eventShortDescription",
      displayName: "Event Short Description",
      type: "string",
      role: "value",
    },
    eventLongDescription: {
      name: "eventLongDescription",
      displayName: "Event Long Description",
      type: "string",
      role: "value",
    },
    eventLocation: {
      name: "eventLocation",
      displayName: "Event Location",
      type: "string",
      role: "value",
    },
    eventBoardingLabel: {
      name: "eventBoardingLabel",
      displayName: "Event Boarding Label",
      type: "string",
      role: "value",
    },
    eventBoardingMinutes: {
      name: "eventBoardingMinutes",
      displayName: "Event Boarding Minutes",
      type: "number",
      role: "value",
    },
    hasFood: {
      name: "hasFood",
      displayName: "Has Food",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    availableSeats: {
      name: "availableSeats",
      displayName: "Available Seats",
      type: "number",
      role: "value",
    },
    isSeated: {
      name: "isSeated",
      displayName: "Is Seated",
      type: "boolean",
      role: "value",
    },
    soldOut: {
      name: "soldOut",
      displayName: "Sold Out",
      type: "boolean",
      role: "value",
    },
    eventCustomSoldOutText: {
      name: "eventCustomSoldOutText",
      displayName: "Event Custom Sold Out Text",
      type: "string",
      role: "value",
    },
    isEventActive: {
      name: "isEventActive",
      displayName: "Is Event Active",
      type: "boolean",
      role: "value",
    },
    isFreeEvent: {
      name: "isFreeEvent",
      displayName: "Is Free Event",
      type: "boolean",
      role: "value",
    },
    hiddenFromInitialPurchase: {
      name: "hiddenFromInitialPurchase",
      displayName: "Hidden From Initial Purchase",
      type: "boolean",
      role: "value",
    },
    statusDescription: {
      name: "statusDescription",
      displayName: "Status Description",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    unpurchasableReason: {
      name: "unpurchasableReason",
      displayName: "Unpurchasable Reason",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    eventPrices: {
      name: "eventPrices",
      displayName: "Event Prices",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventPriceDto as ObjectType) },
      },
      role: "value",
      dontSerialize: true,
    },
    eventBannerImage: {
      name: "eventBannerImage",
      displayName: "Event Banner Image",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    eventImages: {
      name: "eventImages",
      displayName: "Event Images",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventImage as ObjectType) },
      },
      role: "value",
      dontSerialize: true,
    },
  },
  methods: {
  },
  dataSources: {
    calendarSource: {
      type: "dataSource",
      name: "CalendarSource",
      displayName: "Calendar Source",
      props: {
        year: {
          name: "year",
          displayName: "Year",
          type: "number",
          role: "value",
        },
        month: {
          name: "month",
          displayName: "Month",
          type: "number",
          role: "value",
        },
        includeForWeb: {
          name: "includeForWeb",
          displayName: "Include For Web",
          type: "boolean",
          role: "value",
        },
        includeWebUnlisted: {
          name: "includeWebUnlisted",
          displayName: "Include Web Unlisted",
          type: "boolean",
          role: "value",
        },
        includeForPos: {
          name: "includeForPos",
          displayName: "Include For Pos",
          type: "boolean",
          role: "value",
        },
        includeSoldOut: {
          name: "includeSoldOut",
          displayName: "Include Sold Out",
          type: "boolean",
          role: "value",
        },
        includeInactive: {
          name: "includeInactive",
          displayName: "Include Inactive",
          type: "boolean",
          role: "value",
        },
        includeCancelled: {
          name: "includeCancelled",
          displayName: "Include Cancelled",
          type: "boolean",
          role: "value",
        },
        minDate: {
          name: "minDate",
          displayName: "Min Date",
          type: "date",
          dateKind: "datetime",
          role: "value",
        },
        onlyPast: {
          name: "onlyPast",
          displayName: "Only Past",
          type: "boolean",
          role: "value",
        },
        eventTypeId: {
          name: "eventTypeId",
          displayName: "Event Type Id",
          type: "number",
          role: "value",
        },
      },
    },
    defaultSource: {
      type: "dataSource",
      name: "DefaultSource",
      displayName: "Default Source",
      isDefault: true,
      props: {
        includeForWeb: {
          name: "includeForWeb",
          displayName: "Include For Web",
          type: "boolean",
          role: "value",
        },
        includeWebUnlisted: {
          name: "includeWebUnlisted",
          displayName: "Include Web Unlisted",
          type: "boolean",
          role: "value",
        },
        includeForPos: {
          name: "includeForPos",
          displayName: "Include For Pos",
          type: "boolean",
          role: "value",
        },
        includeSoldOut: {
          name: "includeSoldOut",
          displayName: "Include Sold Out",
          type: "boolean",
          role: "value",
        },
        includeInactive: {
          name: "includeInactive",
          displayName: "Include Inactive",
          type: "boolean",
          role: "value",
        },
        includeCancelled: {
          name: "includeCancelled",
          displayName: "Include Cancelled",
          type: "boolean",
          role: "value",
        },
        minDate: {
          name: "minDate",
          displayName: "Min Date",
          type: "date",
          dateKind: "datetime",
          role: "value",
        },
        onlyPast: {
          name: "onlyPast",
          displayName: "Only Past",
          type: "boolean",
          role: "value",
        },
        eventTypeId: {
          name: "eventTypeId",
          displayName: "Event Type Id",
          type: "number",
          role: "value",
        },
      },
    },
  },
}
export const EventDto = domain.types.EventDto = {
  name: "EventDto",
  displayName: "Event Dto",
  get displayProp() { return this.props.description }, 
  type: "model",
  controllerRoute: "EventDto",
  get keyProp() { return this.props.id }, 
  behaviorFlags: 0 as BehaviorFlags,
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "primaryKey",
      hidden: 3 as HiddenAreas,
    },
    description: {
      name: "description",
      displayName: "Description",
      type: "string",
      role: "value",
    },
  },
  methods: {
  },
  dataSources: {
    defaultSource: {
      type: "dataSource",
      name: "DefaultSource",
      displayName: "Default Source",
      isDefault: true,
      props: {
      },
    },
  },
}
export const EventTypeDto = domain.types.EventTypeDto = {
  name: "EventTypeDto",
  displayName: "Event Type Dto",
  get displayProp() { return this.props.description }, 
  type: "model",
  controllerRoute: "EventTypeDto",
  get keyProp() { return this.props.id }, 
  behaviorFlags: 0 as BehaviorFlags,
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "primaryKey",
      hidden: 3 as HiddenAreas,
    },
    description: {
      name: "description",
      displayName: "Description",
      type: "string",
      role: "value",
    },
  },
  methods: {
  },
  dataSources: {
    defaultSource: {
      type: "dataSource",
      name: "DefaultSource",
      displayName: "Default Source",
      isDefault: true,
      props: {
      },
    },
  },
}
export const TicketPurchaseDto = domain.types.TicketPurchaseDto = {
  name: "TicketPurchaseDto",
  displayName: "Ticket Purchase Dto",
  get displayProp() { return this.props.publicId }, 
  type: "model",
  controllerRoute: "TicketPurchaseDto",
  get keyProp() { return this.props.publicId }, 
  behaviorFlags: 2 as BehaviorFlags,
  props: {
    publicId: {
      name: "publicId",
      displayName: "Public Id",
      type: "string",
      role: "primaryKey",
      hidden: 3 as HiddenAreas,
    },
    firstName: {
      name: "firstName",
      displayName: "First Name",
      type: "string",
      role: "value",
    },
    lastName: {
      name: "lastName",
      displayName: "Last Name",
      type: "string",
      role: "value",
    },
    email: {
      name: "email",
      displayName: "Email",
      type: "string",
      role: "value",
    },
    phone: {
      name: "phone",
      displayName: "Phone",
      type: "string",
      role: "value",
    },
    marketingOptOut: {
      name: "marketingOptOut",
      displayName: "Marketing Opt Out",
      type: "boolean",
      role: "value",
    },
    refundable: {
      name: "refundable",
      displayName: "Refundable",
      type: "enum",
      get typeDef() { return domain.enums.RefundableState },
      role: "value",
    },
    isAllowTextMessaging: {
      name: "isAllowTextMessaging",
      displayName: "Is Allow Text Messaging",
      type: "boolean",
      role: "value",
    },
    addressStreet: {
      name: "addressStreet",
      displayName: "Address Street",
      type: "string",
      role: "value",
    },
    addressCity: {
      name: "addressCity",
      displayName: "Address City",
      type: "string",
      role: "value",
    },
    addressState: {
      name: "addressState",
      displayName: "Address State",
      type: "string",
      role: "value",
    },
    addressZip: {
      name: "addressZip",
      displayName: "Address Zip",
      type: "string",
      role: "value",
    },
    addressCountry: {
      name: "addressCountry",
      displayName: "Address Country",
      type: "string",
      role: "value",
    },
    roomNumber: {
      name: "roomNumber",
      displayName: "Room Number",
      type: "string",
      role: "value",
    },
    willCall: {
      name: "willCall",
      displayName: "Will Call",
      type: "boolean",
      role: "value",
    },
    waiveTicketFees: {
      name: "waiveTicketFees",
      displayName: "Waive Ticket Fees",
      type: "boolean",
      role: "value",
    },
    canWaiveTicketFees: {
      name: "canWaiveTicketFees",
      displayName: "Can Waive Ticket Fees",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Creation Date",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
    expirationDate: {
      name: "expirationDate",
      displayName: "Expiration Date",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
    isPublic: {
      name: "isPublic",
      displayName: "Is Public",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    type: {
      name: "type",
      displayName: "Type",
      type: "enum",
      get typeDef() { return domain.enums.PurchaseType },
      role: "value",
      dontSerialize: true,
    },
    status: {
      name: "status",
      displayName: "Status",
      type: "enum",
      get typeDef() { return domain.enums.PurchaseStatus },
      role: "value",
      dontSerialize: true,
    },
    failureMessage: {
      name: "failureMessage",
      displayName: "Failure Message",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    refundComment: {
      name: "refundComment",
      displayName: "Refund Comment",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    confirmationCode: {
      name: "confirmationCode",
      displayName: "Confirmation Code",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    refundPolicy: {
      name: "refundPolicy",
      displayName: "Refund Policy",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    refundableFee: {
      name: "refundableFee",
      displayName: "Refundable Fee",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    refundedRefundableFee: {
      name: "refundedRefundableFee",
      displayName: "Refunded Refundable Fee",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    refundableFeePercentage: {
      name: "refundableFeePercentage",
      displayName: "Refundable Fee Percentage",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    convenienceFee: {
      name: "convenienceFee",
      displayName: "Convenience Fee",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    refundedConvenienceFee: {
      name: "refundedConvenienceFee",
      displayName: "Refunded Convenience Fee",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    salesTax: {
      name: "salesTax",
      displayName: "Sales Tax",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    refundedSalesTax: {
      name: "refundedSalesTax",
      displayName: "Refunded Sales Tax",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    balance: {
      name: "balance",
      displayName: "Balance",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    grandTotal: {
      name: "grandTotal",
      displayName: "Grand Total",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    eventDateId: {
      name: "eventDateId",
      displayName: "Event Date Id",
      type: "number",
      role: "foreignKey",
      get principalKey() { return (domain.types.EventDateDto as ModelType).props.id as PrimaryKeyProperty },
      get principalType() { return (domain.types.EventDateDto as ModelType) },
      get navigationProp() { return (domain.types.TicketPurchaseDto as ModelType).props.eventDate as ModelReferenceNavigationProperty },
      hidden: 3 as HiddenAreas,
      dontSerialize: true,
    },
    eventDate: {
      name: "eventDate",
      displayName: "Event Date",
      type: "model",
      get typeDef() { return (domain.types.EventDateDto as ModelType) },
      role: "referenceNavigation",
      get foreignKey() { return (domain.types.TicketPurchaseDto as ModelType).props.eventDateId as ForeignKeyProperty },
      get principalKey() { return (domain.types.EventDateDto as ModelType).props.id as PrimaryKeyProperty },
      dontSerialize: true,
    },
    tickets: {
      name: "tickets",
      displayName: "Tickets",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.TicketPurchaseTicket as ObjectType) },
      },
      role: "value",
      dontSerialize: true,
    },
    addons: {
      name: "addons",
      displayName: "Addons",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.TicketPurchaseAddon as ObjectType) },
      },
      role: "value",
      dontSerialize: true,
    },
    discounts: {
      name: "discounts",
      displayName: "Discounts",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.TicketPurchaseDiscount as ObjectType) },
      },
      role: "value",
      dontSerialize: true,
    },
    payments: {
      name: "payments",
      displayName: "Payments",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.TicketPurchasePayment as ObjectType) },
      },
      role: "value",
      dontSerialize: true,
    },
    dietaryRestrictions: {
      name: "dietaryRestrictions",
      displayName: "Dietary Restrictions",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.TicketPurchaseDietaryRestriction as ObjectType) },
      },
      role: "value",
      dontSerialize: true,
    },
    availableAddons: {
      name: "availableAddons",
      displayName: "Available Addons",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventAddonDto as ObjectType) },
      },
      role: "value",
      dontSerialize: true,
    },
    eventSuggestedEvents: {
      name: "eventSuggestedEvents",
      displayName: "Event Suggested Events",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventSuggestedEvent as ObjectType) },
      },
      role: "value",
      dontSerialize: true,
    },
    originalPurchase: {
      name: "originalPurchase",
      displayName: "Original Purchase",
      type: "object",
      get typeDef() { return (domain.types.TicketPurchaseRelatedPurchase as ObjectType) },
      role: "value",
      dontSerialize: true,
    },
  },
  methods: {
  },
  dataSources: {
    defaultSource: {
      type: "dataSource",
      name: "DefaultSource",
      displayName: "Default Source",
      isDefault: true,
      props: {
      },
    },
  },
}
export const AddonItemConfiguration = domain.types.AddonItemConfiguration = {
  name: "AddonItemConfiguration",
  displayName: "Addon Item Configuration",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    addonItemDefinitionId: {
      name: "addonItemDefinitionId",
      displayName: "Addon Item Definition Id",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    addonItemDefinition: {
      name: "addonItemDefinition",
      displayName: "Addon Item Definition",
      type: "object",
      get typeDef() { return (domain.types.AddonItemDefinition as ObjectType) },
      role: "value",
    },
    name: {
      name: "name",
      displayName: "Name",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Name is required.",
      }
    },
    marketingDescription: {
      name: "marketingDescription",
      displayName: "Marketing Description",
      type: "string",
      role: "value",
    },
    hasQuantity: {
      name: "hasQuantity",
      displayName: "Has Quantity",
      type: "boolean",
      role: "value",
    },
    type: {
      name: "type",
      displayName: "Type",
      type: "enum",
      get typeDef() { return domain.enums.AddonItemType },
      role: "value",
    },
    fixedAmount: {
      name: "fixedAmount",
      displayName: "Fixed Amount",
      type: "number",
      role: "value",
    },
    percentAmount: {
      name: "percentAmount",
      displayName: "Percent Amount",
      type: "number",
      role: "value",
    },
    ticketTemplateId: {
      name: "ticketTemplateId",
      displayName: "Ticket Template Id",
      type: "number",
      role: "value",
    },
    ticketTemplate: {
      name: "ticketTemplate",
      displayName: "Ticket Template",
      type: "object",
      get typeDef() { return (domain.types.TicketTemplate as ObjectType) },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const AddonItemDefinition = domain.types.AddonItemDefinition = {
  name: "AddonItemDefinition",
  displayName: "Add-on Item Definition",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    name: {
      name: "name",
      displayName: "Name",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Name is required.",
      }
    },
    adminNote: {
      name: "adminNote",
      displayName: "Admin Note",
      type: "string",
      role: "value",
    },
    currentConfigurationId: {
      name: "currentConfigurationId",
      displayName: "Current Configuration Id",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    currentConfiguration: {
      name: "currentConfiguration",
      displayName: "Current Configuration",
      type: "object",
      get typeDef() { return (domain.types.AddonItemConfiguration as ObjectType) },
      role: "value",
      hidden: 3 as HiddenAreas,
    },
    dailyMaximumQuantity: {
      name: "dailyMaximumQuantity",
      displayName: "Daily Maximum Quantity",
      type: "number",
      role: "value",
    },
    showOnPatronManifest: {
      name: "showOnPatronManifest",
      displayName: "Show On Patron Manifest",
      type: "boolean",
      role: "value",
    },
    isHidden: {
      name: "isHidden",
      displayName: "Is Hidden",
      type: "boolean",
      role: "value",
      hidden: 3 as HiddenAreas,
    },
    purchaseNotificationEmail: {
      name: "purchaseNotificationEmail",
      displayName: "Purchase Notification Email",
      type: "string",
      role: "value",
    },
    eventAddonItems: {
      name: "eventAddonItems",
      displayName: "Event Addon Items",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventAddonItemDefinition as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const AppRole = domain.types.AppRole = {
  name: "AppRole",
  displayName: "App Role",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    name: {
      name: "name",
      displayName: "Name",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Name is required.",
      }
    },
    id: {
      name: "id",
      displayName: "Id",
      type: "string",
      role: "value",
    },
  },
}
export const AppUser = domain.types.AppUser = {
  name: "AppUser",
  displayName: "App User",
  get displayProp() { return this.props.userName }, 
  type: "object",
  props: {
    userName: {
      name: "userName",
      displayName: "User Name",
      type: "string",
      role: "value",
    },
    firstName: {
      name: "firstName",
      displayName: "First Name",
      type: "string",
      role: "value",
    },
    lastName: {
      name: "lastName",
      displayName: "Last Name",
      type: "string",
      role: "value",
    },
    receiveLowInventoryNotifications: {
      name: "receiveLowInventoryNotifications",
      displayName: "Receive Low Inventory Notifications",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    userRoles: {
      name: "userRoles",
      displayName: "User Roles",
      description: "The user's roles within the current tenant.",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.AppUserRole as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    appUserAllTenantRoles: {
      name: "appUserAllTenantRoles",
      displayName: "All-tenant Roles",
      description: "Roles assigned to the user when they log into any tenant. Implicitly grants membership to all tenants.",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.AppUserAllTenantRole as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    roleNames: {
      name: "roleNames",
      displayName: "Roles",
      description: "The user's roles within the current tenant.",
      type: "string",
      subtype: "multiline",
      role: "value",
      dontSerialize: true,
    },
    tenantMemberships: {
      name: "tenantMemberships",
      displayName: "Tenant Memberships",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.TenantMembership as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    isTenantAdmin: {
      name: "isTenantAdmin",
      displayName: "Is Tenant Admin",
      description: "Tenant admins are able to create and edit tenants and tenant metadata, and assign all-tenant roles. This role transcends any particular tenant.",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    email: {
      name: "email",
      displayName: "Email",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    emailConfirmed: {
      name: "emailConfirmed",
      displayName: "Email Confirmed",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    accessFailedCount: {
      name: "accessFailedCount",
      displayName: "Access Failed Count",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    lockoutEnd: {
      name: "lockoutEnd",
      displayName: "Lockout End",
      type: "date",
      dateKind: "datetime",
      role: "value",
    },
    id: {
      name: "id",
      displayName: "Id",
      type: "string",
      role: "value",
    },
  },
}
export const AppUserAllTenantRole = domain.types.AppUserAllTenantRole = {
  name: "AppUserAllTenantRole",
  displayName: "App User All Tenant Role",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    userId: {
      name: "userId",
      displayName: "User Id",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "User Id is required.",
      }
    },
    user: {
      name: "user",
      displayName: "User",
      type: "object",
      get typeDef() { return (domain.types.AppUser as ObjectType) },
      role: "value",
    },
    roleId: {
      name: "roleId",
      displayName: "Role Id",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Role Id is required.",
      }
    },
    role: {
      name: "role",
      displayName: "Role",
      type: "object",
      get typeDef() { return (domain.types.AppRole as ObjectType) },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const AppUserRole = domain.types.AppUserRole = {
  name: "AppUserRole",
  displayName: "App User Role",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "string",
      role: "value",
    },
    userId: {
      name: "userId",
      displayName: "User Id",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "User Id is required.",
      }
    },
    user: {
      name: "user",
      displayName: "User",
      type: "object",
      get typeDef() { return (domain.types.AppUser as ObjectType) },
      role: "value",
    },
    roleId: {
      name: "roleId",
      displayName: "Role Id",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Role Id is required.",
      }
    },
    role: {
      name: "role",
      displayName: "Role",
      type: "object",
      get typeDef() { return (domain.types.AppRole as ObjectType) },
      role: "value",
    },
  },
}
export const Consignee = domain.types.Consignee = {
  name: "Consignee",
  displayName: "Consignee",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    name: {
      name: "name",
      displayName: "Name",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Name is required.",
      }
    },
    address: {
      name: "address",
      displayName: "Address",
      type: "string",
      role: "value",
    },
    contactPhone: {
      name: "contactPhone",
      displayName: "Contact Phone",
      type: "string",
      role: "value",
    },
    taxId: {
      name: "taxId",
      displayName: "Tax Id",
      type: "string",
      role: "value",
    },
    consigneeDiscountDefinitions: {
      name: "consigneeDiscountDefinitions",
      displayName: "Consignee Discount Definitions",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.ConsigneeDiscountDefinition as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    consigneeReps: {
      name: "consigneeReps",
      displayName: "Consignee Reps",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.ConsigneeRep as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    consigneeCommissions: {
      name: "consigneeCommissions",
      displayName: "Consignee Commissions",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.ConsigneeCommission as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const ConsigneeCommission = domain.types.ConsigneeCommission = {
  name: "ConsigneeCommission",
  displayName: "Consignee Commission",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    commissionPercent: {
      name: "commissionPercent",
      displayName: "Commission Percent",
      type: "number",
      role: "value",
    },
    commissionRate: {
      name: "commissionRate",
      displayName: "Commission Rate",
      type: "number",
      role: "value",
    },
    consigneeId: {
      name: "consigneeId",
      displayName: "Consignee Id",
      type: "number",
      role: "value",
    },
    consignee: {
      name: "consignee",
      displayName: "Consignee",
      type: "object",
      get typeDef() { return (domain.types.Consignee as ObjectType) },
      role: "value",
    },
    isActive: {
      name: "isActive",
      displayName: "Is Active",
      type: "boolean",
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const ConsigneeDiscountDefinition = domain.types.ConsigneeDiscountDefinition = {
  name: "ConsigneeDiscountDefinition",
  displayName: "Consignee Discount Definition",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    consigneeId: {
      name: "consigneeId",
      displayName: "Consignee Id",
      type: "number",
      role: "value",
    },
    discountDefinitionId: {
      name: "discountDefinitionId",
      displayName: "Discount Definition Id",
      type: "number",
      role: "value",
    },
    consignee: {
      name: "consignee",
      displayName: "Consignee",
      type: "object",
      get typeDef() { return (domain.types.Consignee as ObjectType) },
      role: "value",
    },
    discountDefinition: {
      name: "discountDefinition",
      displayName: "Discount Definition",
      type: "object",
      get typeDef() { return (domain.types.DiscountDefinition as ObjectType) },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const ConsigneeRep = domain.types.ConsigneeRep = {
  name: "ConsigneeRep",
  displayName: "Consignee Rep",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    consigneeId: {
      name: "consigneeId",
      displayName: "Consignee Id",
      type: "number",
      role: "value",
    },
    consignee: {
      name: "consignee",
      displayName: "Consignee",
      type: "object",
      get typeDef() { return (domain.types.Consignee as ObjectType) },
      role: "value",
    },
    userId: {
      name: "userId",
      displayName: "User Id",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "User Id is required.",
      }
    },
    user: {
      name: "user",
      displayName: "User",
      type: "object",
      get typeDef() { return (domain.types.AppUser as ObjectType) },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const CreditCardDetails = domain.types.CreditCardDetails = {
  name: "CreditCardDetails",
  displayName: "Credit Card Details",
  type: "object",
  props: {
    paymentId: {
      name: "paymentId",
      displayName: "Payment Id",
      type: "number",
      role: "value",
    },
    cardNumber: {
      name: "cardNumber",
      displayName: "Card Number",
      type: "string",
      role: "value",
    },
    expirationMonth: {
      name: "expirationMonth",
      displayName: "Expiration Month",
      type: "number",
      role: "value",
    },
    expirationYear: {
      name: "expirationYear",
      displayName: "Expiration Year",
      type: "number",
      role: "value",
    },
    cvv: {
      name: "cvv",
      displayName: "Cvv",
      type: "string",
      role: "value",
    },
    billingAddress: {
      name: "billingAddress",
      displayName: "Billing Address",
      type: "string",
      role: "value",
    },
    billingZip: {
      name: "billingZip",
      displayName: "Billing Zip",
      type: "string",
      role: "value",
    },
    billingCountry: {
      name: "billingCountry",
      displayName: "Billing Country",
      type: "string",
      role: "value",
    },
    billingState: {
      name: "billingState",
      displayName: "Billing State",
      type: "string",
      role: "value",
    },
  },
}
export const DiscountDefinition = domain.types.DiscountDefinition = {
  name: "DiscountDefinition",
  displayName: "Discount Definition",
  get displayProp() { return this.props.displayName }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    displayName: {
      name: "displayName",
      displayName: "Name",
      description: "The name of the discount, shown to patrons.",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Name is required.",
        maxLength: val => !val || val.length <= 100 || "Name may not be more than 100 characters.",
      }
    },
    details: {
      name: "details",
      displayName: "Internal Description",
      description: "An internal description of the discount, shown to admins and event managers.",
      type: "string",
      role: "value",
    },
    effectSummary: {
      name: "effectSummary",
      displayName: "Effect Summary",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    nonUniqueCode: {
      name: "nonUniqueCode",
      displayName: "Non Unique Code",
      type: "string",
      role: "value",
      rules: {
        maxLength: val => !val || val.length <= 20 || "Non Unique Code may not be more than 20 characters.",
      }
    },
    isActive: {
      name: "isActive",
      displayName: "Is Active",
      type: "boolean",
      role: "value",
    },
    availableOnWeb: {
      name: "availableOnWeb",
      displayName: "Available On Web",
      type: "boolean",
      role: "value",
    },
    availableOnPos: {
      name: "availableOnPos",
      displayName: "Available On Pos",
      type: "boolean",
      role: "value",
    },
    autoApply: {
      name: "autoApply",
      displayName: "Auto Apply",
      type: "boolean",
      role: "value",
    },
    isHidden: {
      name: "isHidden",
      displayName: "Is Hidden",
      type: "boolean",
      role: "value",
      hidden: 3 as HiddenAreas,
    },
    currentConfigurationId: {
      name: "currentConfigurationId",
      displayName: "Current Configuration Id",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    currentConfiguration: {
      name: "currentConfiguration",
      displayName: "Current Configuration",
      type: "object",
      get typeDef() { return (domain.types.DiscountDefinitionConfiguration as ObjectType) },
      role: "value",
      hidden: 3 as HiddenAreas,
    },
    consigneeDiscountDefinitions: {
      name: "consigneeDiscountDefinitions",
      displayName: "Consignee Discount Definitions",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.ConsigneeDiscountDefinition as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    eventDiscountDefinitions: {
      name: "eventDiscountDefinitions",
      displayName: "Event Discount Definitions",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventDiscountDefinition as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    eventDateDiscountDefinitions: {
      name: "eventDateDiscountDefinitions",
      displayName: "Event Date Discount Definitions",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventDateDiscountDefinition as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const DiscountDefinitionConfiguration = domain.types.DiscountDefinitionConfiguration = {
  name: "DiscountDefinitionConfiguration",
  displayName: "Discount Definition Configuration",
  get displayProp() { return this.props.displayName }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    discountDefinitionId: {
      name: "discountDefinitionId",
      displayName: "Discount Definition Id",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    discountDefinition: {
      name: "discountDefinition",
      displayName: "Discount Definition",
      type: "object",
      get typeDef() { return (domain.types.DiscountDefinition as ObjectType) },
      role: "value",
    },
    displayName: {
      name: "displayName",
      displayName: "Name",
      description: "The name of the discount, shown to patrons.",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Name is required.",
        maxLength: val => !val || val.length <= 100 || "Name may not be more than 100 characters.",
      }
    },
    discountType: {
      name: "discountType",
      displayName: "Discount Type",
      type: "enum",
      get typeDef() { return domain.enums.DiscountType },
      role: "value",
      rules: {
        required: val => val != null || "Discount Type is required.",
      }
    },
    amountOff: {
      name: "amountOff",
      displayName: "Amount Off",
      type: "number",
      role: "value",
      rules: {
        min: val => val == null || val >= 0 || "Amount Off must be at least 0.",
        max: val => val == null || val <= 2147483647 || "Amount Off may not be more than 2147483647.",
      }
    },
    percentageOff: {
      name: "percentageOff",
      displayName: "Percentage Off",
      type: "number",
      role: "value",
      rules: {
        min: val => val == null || val >= 0 || "Percentage Off must be at least 0.",
        max: val => val == null || val <= 100 || "Percentage Off may not be more than 100.",
      }
    },
    minimumTicketCount: {
      name: "minimumTicketCount",
      displayName: "Amount of tickets sold for discount to apply",
      description: "The amount of tickets to be sold to apply discount.",
      type: "number",
      role: "value",
      rules: {
        min: val => val == null || val >= 0 || "Amount of tickets sold for discount to apply must be at least 0.",
        max: val => val == null || val <= 2147483647 || "Amount of tickets sold for discount to apply may not be more than 2147483647.",
      }
    },
    minimumPurchaseValue: {
      name: "minimumPurchaseValue",
      displayName: "Minimum price in one purchase to apply discount",
      description: "The minimum sum of Ticket Price (excluding all taxes and fees) required to use this discount.",
      type: "number",
      role: "value",
      rules: {
        min: val => val == null || val >= 0 || "Minimum price in one purchase to apply discount must be at least 0.",
        max: val => val == null || val <= 2147483647 || "Minimum price in one purchase to apply discount may not be more than 2147483647.",
      }
    },
    expireDaysBefore: {
      name: "expireDaysBefore",
      displayName: "Number of Days before the event discount expires",
      description: "The number of days before each event date when this discount becomes unavailable. A value of 0 disables date-based expiration.",
      type: "number",
      role: "value",
      rules: {
        min: val => val == null || val >= 0 || "Number of Days before the event discount expires must be at least 0.",
        max: val => val == null || val <= 365 || "Number of Days before the event discount expires may not be more than 365.",
      }
    },
    effectSummary: {
      name: "effectSummary",
      displayName: "Effect Summary",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EmailTemplate = domain.types.EmailTemplate = {
  name: "EmailTemplate",
  displayName: "Email Template",
  get displayProp() { return this.props.description }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    description: {
      name: "description",
      displayName: "Description",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Description is required.",
      }
    },
    subject: {
      name: "subject",
      displayName: "Subject",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Subject is required.",
      }
    },
    bodyText: {
      name: "bodyText",
      displayName: "Body Text",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    bodyHtml: {
      name: "bodyHtml",
      displayName: "Body Html",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      rules: {
        required: val => (val != null && val !== '') || "Body Html is required.",
      }
    },
    designJson: {
      name: "designJson",
      displayName: "Design Json",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    type: {
      name: "type",
      displayName: "Type",
      type: "enum",
      get typeDef() { return domain.enums.EmailType },
      role: "value",
    },
    isActive: {
      name: "isActive",
      displayName: "Is Active",
      type: "boolean",
      role: "value",
    },
    eventEmailTemplates: {
      name: "eventEmailTemplates",
      displayName: "Event Email Templates",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventEmailTemplate as ObjectType) },
      },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const Event = domain.types.Event = {
  name: "Event",
  displayName: "Event",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    name: {
      name: "name",
      displayName: "Name",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Name is required.",
      }
    },
    defaultCapacity: {
      name: "defaultCapacity",
      displayName: "Default Capacity",
      type: "number",
      role: "value",
    },
    boardingTime: {
      name: "boardingTime",
      displayName: "Boarding Time",
      type: "number",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    location: {
      name: "location",
      displayName: "Location",
      type: "string",
      role: "value",
    },
    shortDescription: {
      name: "shortDescription",
      displayName: "Short Description",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    longDescription: {
      name: "longDescription",
      displayName: "Long Description",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    customSoldOutText: {
      name: "customSoldOutText",
      displayName: "Custom Sold Out Text",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    isActive: {
      name: "isActive",
      displayName: "Is Active",
      type: "boolean",
      role: "value",
    },
    forSaleOnWeb: {
      name: "forSaleOnWeb",
      displayName: "For Sale On Web",
      type: "boolean",
      role: "value",
    },
    unlistedOnWeb: {
      name: "unlistedOnWeb",
      displayName: "Unlisted On Web",
      type: "boolean",
      role: "value",
    },
    forSaleOnPos: {
      name: "forSaleOnPos",
      displayName: "For Sale On Pos",
      type: "boolean",
      role: "value",
    },
    unlistedOnWebAfterFirstPurchase: {
      name: "unlistedOnWebAfterFirstPurchase",
      displayName: "UnlistedOnWebInTheNextPurchase",
      type: "boolean",
      role: "value",
    },
    eventTypeId: {
      name: "eventTypeId",
      displayName: "Event Type Id",
      type: "number",
      role: "value",
    },
    eventType: {
      name: "eventType",
      displayName: "Event Type",
      type: "object",
      get typeDef() { return (domain.types.EventType as ObjectType) },
      role: "value",
    },
    ticketTemplateId: {
      name: "ticketTemplateId",
      displayName: "Ticket Template Id",
      type: "number",
      role: "value",
    },
    ticketTemplate: {
      name: "ticketTemplate",
      displayName: "Ticket Template",
      type: "object",
      get typeDef() { return (domain.types.TicketTemplate as ObjectType) },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    confirmationEmailId: {
      name: "confirmationEmailId",
      displayName: "Confirmation Email Id",
      type: "number",
      role: "value",
    },
    confirmationEmail: {
      name: "confirmationEmail",
      displayName: "Confirmation Email",
      type: "object",
      get typeDef() { return (domain.types.EmailTemplate as ObjectType) },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    cancellationEmailId: {
      name: "cancellationEmailId",
      displayName: "Cancellation Email Id",
      type: "number",
      role: "value",
    },
    cancellationEmail: {
      name: "cancellationEmail",
      displayName: "Cancellation Email",
      type: "object",
      get typeDef() { return (domain.types.EmailTemplate as ObjectType) },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    venueId: {
      name: "venueId",
      displayName: "Venue Id",
      type: "number",
      role: "value",
    },
    venue: {
      name: "venue",
      displayName: "Venue",
      type: "object",
      get typeDef() { return (domain.types.Venue as ObjectType) },
      role: "value",
    },
    seatMapId: {
      name: "seatMapId",
      displayName: "Seat Map Id",
      type: "number",
      role: "value",
    },
    seatMap: {
      name: "seatMap",
      displayName: "Seat Map",
      type: "object",
      get typeDef() { return (domain.types.SeatMap as ObjectType) },
      role: "value",
    },
    eventSuggestedList: {
      name: "eventSuggestedList",
      displayName: "Event Suggested List",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventSuggestedEvent as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    premiumRefundsEnabled: {
      name: "premiumRefundsEnabled",
      displayName: "Premium Refunds Enabled",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    refundableFeePercentage: {
      name: "refundableFeePercentage",
      displayName: "Refundable Fee Percentage",
      type: "number",
      role: "value",
      hidden: 3 as HiddenAreas,
      dontSerialize: true,
    },
    isTenantShowImageOnListView: {
      name: "isTenantShowImageOnListView",
      displayName: "Is Tenant Show Image On List View",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    isTenantAllowFreeEvent: {
      name: "isTenantAllowFreeEvent",
      displayName: "Is Tenant Allow Free Event",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    isTenantShowRemainingSeats: {
      name: "isTenantShowRemainingSeats",
      displayName: "Is Tenant Show Remaining Seats",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    hasFood: {
      name: "hasFood",
      displayName: "Has Food",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    seatSections: {
      name: "seatSections",
      displayName: "Seat Sections",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventSection as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    eventDates: {
      name: "eventDates",
      displayName: "Event Dates",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventDate as ObjectType) },
      },
      role: "value",
    },
    eventImages: {
      name: "eventImages",
      displayName: "Event Images",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventImage as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    bannerImageSrc: {
      name: "bannerImageSrc",
      displayName: "Banner Image",
      type: "string",
      subtype: "url-image",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    eventDiscountDefinitions: {
      name: "eventDiscountDefinitions",
      displayName: "Event Discount Definitions",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventDiscountDefinition as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    eventEmailTemplates: {
      name: "eventEmailTemplates",
      displayName: "Event Email Templates",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventEmailTemplate as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    eventAddonItems: {
      name: "eventAddonItems",
      displayName: "Event Addon Items",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventAddonItemDefinition as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    eventPrices: {
      name: "eventPrices",
      displayName: "Event Prices",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventPrice as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    activeEventPrices: {
      name: "activeEventPrices",
      displayName: "Active Event Prices",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventPrice as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    isFreeEvent: {
      name: "isFreeEvent",
      displayName: "Is Free Event",
      type: "boolean",
      role: "value",
    },
    notifyViaEmailWhenTicketPurchased: {
      name: "notifyViaEmailWhenTicketPurchased",
      displayName: "Purchase Email Notification",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventAddonDto = domain.types.EventAddonDto = {
  name: "EventAddonDto",
  displayName: "Event Addon Dto",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    definitionId: {
      name: "definitionId",
      displayName: "Definition Id",
      type: "number",
      role: "value",
    },
    configurationId: {
      name: "configurationId",
      displayName: "Configuration Id",
      type: "number",
      role: "value",
    },
    name: {
      name: "name",
      displayName: "Name",
      type: "string",
      role: "value",
    },
    description: {
      name: "description",
      displayName: "Description",
      type: "string",
      role: "value",
    },
    price: {
      name: "price",
      displayName: "Price",
      type: "number",
      role: "value",
    },
    priceDescription: {
      name: "priceDescription",
      displayName: "Price Description",
      type: "string",
      role: "value",
    },
    hasQuantity: {
      name: "hasQuantity",
      displayName: "Has Quantity",
      type: "boolean",
      role: "value",
    },
  },
}
export const EventAddonItemDefinition = domain.types.EventAddonItemDefinition = {
  name: "EventAddonItemDefinition",
  displayName: "Event Addon Item Definition",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventId: {
      name: "eventId",
      displayName: "Event Id",
      type: "number",
      role: "value",
    },
    addonItemDefinitionId: {
      name: "addonItemDefinitionId",
      displayName: "Addon Item Definition Id",
      type: "number",
      role: "value",
    },
    addonItemDefinition: {
      name: "addonItemDefinition",
      displayName: "Addon Item Definition",
      type: "object",
      get typeDef() { return (domain.types.AddonItemDefinition as ObjectType) },
      role: "value",
    },
    event: {
      name: "event",
      displayName: "Event",
      type: "object",
      get typeDef() { return (domain.types.Event as ObjectType) },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventDate = domain.types.EventDate = {
  name: "EventDate",
  displayName: "Event Date",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventStartDateTime: {
      name: "eventStartDateTime",
      displayName: "Event Start Date Time",
      type: "date",
      dateKind: "datetime",
      role: "value",
      rules: {
        required: val => val != null || "Event Start Date Time is required.",
      }
    },
    eventEndDateTime: {
      name: "eventEndDateTime",
      displayName: "Event End Date Time",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
    durationMinutes: {
      name: "durationMinutes",
      displayName: "Duration Minutes",
      type: "number",
      role: "value",
    },
    eventPublicPurchaseDatetime: {
      name: "eventPublicPurchaseDatetime",
      displayName: "Event Public Purchase Datetime",
      type: "date",
      dateKind: "datetime",
      role: "value",
    },
    eventCapacity: {
      name: "eventCapacity",
      displayName: "Event Capacity",
      type: "number",
      role: "value",
    },
    cancelled: {
      name: "cancelled",
      displayName: "Cancelled",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    hiddenFromInitialPurchase: {
      name: "hiddenFromInitialPurchase",
      displayName: "Hidden From Initial Purchase",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    eventId: {
      name: "eventId",
      displayName: "Event Id",
      type: "number",
      role: "value",
    },
    event: {
      name: "event",
      displayName: "Event",
      type: "object",
      get typeDef() { return (domain.types.Event as ObjectType) },
      role: "value",
    },
    eventDateDiscountDefinitions: {
      name: "eventDateDiscountDefinitions",
      displayName: "Event Date Discount Definitions",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventDateDiscountDefinition as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    seatRestrictions: {
      name: "seatRestrictions",
      displayName: "Seat Restrictions",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventDateSeatRestriction as ObjectType) },
      },
      role: "value",
    },
    priceConfigurations: {
      name: "priceConfigurations",
      displayName: "Price Configurations",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventDatePriceConfig as ObjectType) },
      },
      role: "value",
    },
    availableSeats: {
      name: "availableSeats",
      displayName: "Available Seats",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    availableSeatsCacheDate: {
      name: "availableSeatsCacheDate",
      displayName: "Available Seats Cache Date",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventDateDiscountDefinition = domain.types.EventDateDiscountDefinition = {
  name: "EventDateDiscountDefinition",
  displayName: "Event Date Discount Definition",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventDateId: {
      name: "eventDateId",
      displayName: "Event Date Id",
      type: "number",
      role: "value",
    },
    discountDefinitionId: {
      name: "discountDefinitionId",
      displayName: "Discount Definition Id",
      type: "number",
      role: "value",
    },
    discountDefinition: {
      name: "discountDefinition",
      displayName: "Discount Definition",
      type: "object",
      get typeDef() { return (domain.types.DiscountDefinition as ObjectType) },
      role: "value",
    },
    eventDate: {
      name: "eventDate",
      displayName: "Event Date",
      type: "object",
      get typeDef() { return (domain.types.EventDate as ObjectType) },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventDatePriceConfig = domain.types.EventDatePriceConfig = {
  name: "EventDatePriceConfig",
  displayName: "Event Date Price Config",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventDateId: {
      name: "eventDateId",
      displayName: "Event Date Id",
      type: "number",
      role: "value",
    },
    eventDate: {
      name: "eventDate",
      displayName: "Event Date",
      type: "object",
      get typeDef() { return (domain.types.EventDate as ObjectType) },
      role: "value",
    },
    eventPriceRootId: {
      name: "eventPriceRootId",
      displayName: "Event Price Root Id",
      type: "number",
      role: "value",
    },
    maxQuantity: {
      name: "maxQuantity",
      displayName: "Max Quantity",
      type: "number",
      role: "value",
    },
    availableTickets: {
      name: "availableTickets",
      displayName: "Available Tickets",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventDateSeatingInfo = domain.types.EventDateSeatingInfo = {
  name: "EventDateSeatingInfo",
  displayName: "Event Date Seating Info",
  type: "object",
  props: {
    eventDateId: {
      name: "eventDateId",
      displayName: "Event Date Id",
      type: "number",
      role: "value",
    },
    seatMap: {
      name: "seatMap",
      displayName: "Seat Map",
      type: "object",
      get typeDef() { return (domain.types.SeatMapDto as ObjectType) },
      role: "value",
    },
    availableSeats: {
      name: "availableSeats",
      displayName: "Available Seats",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "string",
      },
      role: "value",
    },
    restrictions: {
      name: "restrictions",
      displayName: "Restrictions",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventDateSeatingRestrictionInfo as ObjectType) },
      },
      role: "value",
    },
  },
}
export const EventDateSeatingRestrictionInfo = domain.types.EventDateSeatingRestrictionInfo = {
  name: "EventDateSeatingRestrictionInfo",
  displayName: "Event Date Seating Restriction Info",
  type: "object",
  props: {
    seats: {
      name: "seats",
      displayName: "Seats",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "string",
      },
      role: "value",
    },
    agentOnly: {
      name: "agentOnly",
      displayName: "Agent Only",
      type: "boolean",
      role: "value",
    },
    agentNote: {
      name: "agentNote",
      displayName: "Agent Note",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventDateSeatRestriction = domain.types.EventDateSeatRestriction = {
  name: "EventDateSeatRestriction",
  displayName: "Event Date Seat Restriction",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventDateId: {
      name: "eventDateId",
      displayName: "Event Date Id",
      type: "number",
      role: "value",
    },
    eventDate: {
      name: "eventDate",
      displayName: "Event Date",
      type: "object",
      get typeDef() { return (domain.types.EventDate as ObjectType) },
      role: "value",
    },
    agentOnly: {
      name: "agentOnly",
      displayName: "Agent Only",
      type: "boolean",
      role: "value",
    },
    agentNote: {
      name: "agentNote",
      displayName: "Agent Note",
      type: "string",
      role: "value",
    },
    seatNumbersJson: {
      name: "seatNumbersJson",
      displayName: "Seat Numbers Json",
      type: "string",
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventDiscountDefinition = domain.types.EventDiscountDefinition = {
  name: "EventDiscountDefinition",
  displayName: "Event Discount Definition",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventId: {
      name: "eventId",
      displayName: "Event Id",
      type: "number",
      role: "value",
    },
    discountDefinitionId: {
      name: "discountDefinitionId",
      displayName: "Discount Definition Id",
      type: "number",
      role: "value",
    },
    discountDefinition: {
      name: "discountDefinition",
      displayName: "Discount Definition",
      type: "object",
      get typeDef() { return (domain.types.DiscountDefinition as ObjectType) },
      role: "value",
    },
    event: {
      name: "event",
      displayName: "Event",
      type: "object",
      get typeDef() { return (domain.types.Event as ObjectType) },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventEmailTemplate = domain.types.EventEmailTemplate = {
  name: "EventEmailTemplate",
  displayName: "Event Email Template",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventId: {
      name: "eventId",
      displayName: "Event Id",
      type: "number",
      role: "value",
    },
    emailTemplateId: {
      name: "emailTemplateId",
      displayName: "Email Template Id",
      type: "number",
      role: "value",
    },
    emailTemplate: {
      name: "emailTemplate",
      displayName: "Email Template",
      type: "object",
      get typeDef() { return (domain.types.EmailTemplate as ObjectType) },
      role: "value",
    },
    event: {
      name: "event",
      displayName: "Event",
      type: "object",
      get typeDef() { return (domain.types.Event as ObjectType) },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventImage = domain.types.EventImage = {
  name: "EventImage",
  displayName: "Event Image",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventId: {
      name: "eventId",
      displayName: "Event Id",
      type: "number",
      role: "value",
      rules: {
        required: val => val != null || "Event Id is required.",
      }
    },
    imageSrc: {
      name: "imageSrc",
      displayName: "Image Src",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Image Src is required.",
      }
    },
    altText: {
      name: "altText",
      displayName: "Alt Text",
      type: "string",
      role: "value",
    },
    order: {
      name: "order",
      displayName: "Order",
      type: "number",
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventPrice = domain.types.EventPrice = {
  name: "EventPrice",
  displayName: "Event Price",
  get displayProp() { return this.props.displayName }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    rootId: {
      name: "rootId",
      displayName: "Root Id",
      type: "number",
      role: "value",
    },
    eventId: {
      name: "eventId",
      displayName: "Event Id",
      type: "number",
      role: "value",
    },
    event: {
      name: "event",
      displayName: "Event",
      type: "object",
      get typeDef() { return (domain.types.Event as ObjectType) },
      role: "value",
    },
    activeEventId: {
      name: "activeEventId",
      displayName: "Active Event Id",
      type: "number",
      role: "value",
    },
    activeEvent: {
      name: "activeEvent",
      displayName: "Active Event",
      type: "object",
      get typeDef() { return (domain.types.Event as ObjectType) },
      role: "value",
    },
    displayName: {
      name: "displayName",
      displayName: "Display Name",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Display Name is required.",
        maxLength: val => !val || val.length <= 100 || "Display Name may not be more than 100 characters.",
      }
    },
    details: {
      name: "details",
      displayName: "Details",
      type: "string",
      role: "value",
      rules: {
        maxLength: val => !val || val.length <= 100 || "Details may not be more than 100 characters.",
      }
    },
    price: {
      name: "price",
      displayName: "Price",
      type: "number",
      role: "value",
      rules: {
        required: val => val != null || "Price is required.",
      }
    },
    serviceFee: {
      name: "serviceFee",
      displayName: "Service Fee",
      type: "number",
      role: "value",
    },
    currentServiceFeeFromTenant: {
      name: "currentServiceFeeFromTenant",
      displayName: "Current Service Fee From Tenant",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    revenueDistributions: {
      name: "revenueDistributions",
      displayName: "Revenue Distributions",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventPriceRevenueDistribution as ObjectType) },
      },
      role: "value",
    },
    seats: {
      name: "seats",
      displayName: "Seats",
      type: "number",
      role: "value",
      rules: {
        min: val => val == null || val >= 0 || "Seats must be at least 0.",
      }
    },
    defaultMaxQuantity: {
      name: "defaultMaxQuantity",
      displayName: "Default Max Quantity",
      type: "number",
      role: "value",
    },
    minimumQuantity: {
      name: "minimumQuantity",
      displayName: "Minimum Quantity",
      type: "number",
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventPriceDto = domain.types.EventPriceDto = {
  name: "EventPriceDto",
  displayName: "Event Price Dto",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    rootId: {
      name: "rootId",
      displayName: "Root Id",
      type: "number",
      role: "value",
    },
    displayName: {
      name: "displayName",
      displayName: "Display Name",
      type: "string",
      role: "value",
    },
    description: {
      name: "description",
      displayName: "Description",
      type: "string",
      role: "value",
    },
    price: {
      name: "price",
      displayName: "Price",
      type: "number",
      role: "value",
    },
    serviceFee: {
      name: "serviceFee",
      displayName: "Service Fee",
      type: "number",
      role: "value",
    },
    seats: {
      name: "seats",
      displayName: "Seats",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    minimumQuantity: {
      name: "minimumQuantity",
      displayName: "Minimum Quantity",
      type: "number",
      role: "value",
    },
  },
}
export const EventPriceRevenueDistribution = domain.types.EventPriceRevenueDistribution = {
  name: "EventPriceRevenueDistribution",
  displayName: "Event Price Revenue Distribution",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventPriceId: {
      name: "eventPriceId",
      displayName: "Event Price Id",
      type: "number",
      role: "value",
    },
    eventPrice: {
      name: "eventPrice",
      displayName: "Event Price",
      type: "object",
      get typeDef() { return (domain.types.EventPrice as ObjectType) },
      role: "value",
    },
    revenueCategoryId: {
      name: "revenueCategoryId",
      displayName: "Revenue Category Id",
      type: "number",
      role: "value",
    },
    revenueCategory: {
      name: "revenueCategory",
      displayName: "Revenue Category",
      type: "object",
      get typeDef() { return (domain.types.RevenueCategory as ObjectType) },
      role: "value",
    },
    revenue: {
      name: "revenue",
      displayName: "Revenue",
      type: "number",
      role: "value",
      rules: {
        required: val => val != null || "Revenue is required.",
      }
    },
  },
}
export const EventSection = domain.types.EventSection = {
  name: "EventSection",
  displayName: "Event Section",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventId: {
      name: "eventId",
      displayName: "Event Id",
      type: "number",
      role: "value",
    },
    event: {
      name: "event",
      displayName: "Event",
      type: "object",
      get typeDef() { return (domain.types.Event as ObjectType) },
      role: "value",
    },
    seatMapSectionId: {
      name: "seatMapSectionId",
      displayName: "Seat Map Section Id",
      type: "number",
      role: "value",
    },
    seatMapSection: {
      name: "seatMapSection",
      displayName: "Seat Map Section",
      type: "object",
      get typeDef() { return (domain.types.SeatMapSection as ObjectType) },
      role: "value",
    },
    isEnabled: {
      name: "isEnabled",
      displayName: "Is Enabled",
      type: "boolean",
      role: "value",
    },
    prices: {
      name: "prices",
      displayName: "Prices",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventSectionPrice as ObjectType) },
      },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventSectionPrice = domain.types.EventSectionPrice = {
  name: "EventSectionPrice",
  displayName: "Event Section Price",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventSectionId: {
      name: "eventSectionId",
      displayName: "Event Section Id",
      type: "number",
      role: "value",
    },
    eventSection: {
      name: "eventSection",
      displayName: "Event Section",
      type: "object",
      get typeDef() { return (domain.types.EventSection as ObjectType) },
      role: "value",
    },
    eventPriceId: {
      name: "eventPriceId",
      displayName: "Event Price Id",
      type: "number",
      role: "value",
    },
    eventPrice: {
      name: "eventPrice",
      displayName: "Event Price",
      type: "object",
      get typeDef() { return (domain.types.EventPrice as ObjectType) },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventSuggestedEvent = domain.types.EventSuggestedEvent = {
  name: "EventSuggestedEvent",
  displayName: "Event Suggested Event",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    eventId: {
      name: "eventId",
      displayName: "Event Id",
      type: "number",
      role: "value",
    },
    event: {
      name: "event",
      displayName: "Event",
      type: "object",
      get typeDef() { return (domain.types.Event as ObjectType) },
      role: "value",
    },
    suggestedEventId: {
      name: "suggestedEventId",
      displayName: "Suggested Event Id",
      type: "number",
      role: "value",
    },
    suggestedEvent: {
      name: "suggestedEvent",
      displayName: "Suggested Event",
      type: "object",
      get typeDef() { return (domain.types.Event as ObjectType) },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const EventType = domain.types.EventType = {
  name: "EventType",
  displayName: "Event Type",
  get displayProp() { return this.props.shortDescription }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    shortDescription: {
      name: "shortDescription",
      displayName: "Short Description",
      description: "Shown to users in the Event Type filters on the calendar + list views.",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Short Description is required.",
      }
    },
    longDescription: {
      name: "longDescription",
      displayName: "Long Description",
      description: "This field is currently not shown anywhere in the app.",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Long Description is required.",
      }
    },
    refundPolicy: {
      name: "refundPolicy",
      displayName: "Refund Policy",
      description: "A description of the refund policy for Events of this type to be displayed to users alongside the refunds option (if enabled for the Event) during the checkout process.",
      type: "string",
      subtype: "multiline",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    events: {
      name: "events",
      displayName: "Events",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.Event as ObjectType) },
      },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const PaymentGateway = domain.types.PaymentGateway = {
  name: "PaymentGateway",
  displayName: "Payment Gateway",
  get displayProp() { return this.props.displayName }, 
  type: "object",
  props: {
    paymentGatewayId: {
      name: "paymentGatewayId",
      displayName: "Payment Gateway Id",
      type: "number",
      role: "value",
    },
    displayName: {
      name: "displayName",
      displayName: "Display Name",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Display Name is required.",
      }
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const PostalCodeInfo = domain.types.PostalCodeInfo = {
  name: "PostalCodeInfo",
  displayName: "Postal Code Info",
  type: "object",
  props: {
    country: {
      name: "country",
      displayName: "Country",
      type: "string",
      role: "value",
    },
    zip: {
      name: "zip",
      displayName: "Zip",
      type: "string",
      role: "value",
    },
    city: {
      name: "city",
      displayName: "City",
      type: "string",
      role: "value",
    },
    state: {
      name: "state",
      displayName: "State",
      type: "string",
      role: "value",
    },
    stateAbbr: {
      name: "stateAbbr",
      displayName: "State Abbr",
      type: "string",
      role: "value",
    },
  },
}
export const RevenueCategory = domain.types.RevenueCategory = {
  name: "RevenueCategory",
  displayName: "Revenue Category",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    name: {
      name: "name",
      displayName: "Name",
      description: "Name used in report headings, recorded immutably in the app's transaction log.",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Name is required.",
        maxLength: val => !val || val.length <= 50 || "Name may not be more than 50 characters.",
      }
    },
    description: {
      name: "description",
      displayName: "Description",
      description: "Explanation of the purpose of this category.",
      type: "string",
      role: "value",
    },
    order: {
      name: "order",
      displayName: "Order",
      description: "The order of this category in reports.",
      type: "number",
      role: "value",
    },
    isArchived: {
      name: "isArchived",
      displayName: "Is Archived",
      type: "boolean",
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const SeatMap = domain.types.SeatMap = {
  name: "SeatMap",
  displayName: "Seat Map",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    venueId: {
      name: "venueId",
      displayName: "Venue Id",
      type: "number",
      role: "value",
    },
    venue: {
      name: "venue",
      displayName: "Venue",
      type: "object",
      get typeDef() { return (domain.types.Venue as ObjectType) },
      role: "value",
    },
    name: {
      name: "name",
      displayName: "Name",
      type: "string",
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const SeatMapAnnotationDto = domain.types.SeatMapAnnotationDto = {
  name: "SeatMapAnnotationDto",
  displayName: "Seat Map Annotation Dto",
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    x: {
      name: "x",
      displayName: "X",
      type: "number",
      role: "value",
    },
    y: {
      name: "y",
      displayName: "Y",
      type: "number",
      role: "value",
    },
    width: {
      name: "width",
      displayName: "Width",
      type: "number",
      role: "value",
    },
    height: {
      name: "height",
      displayName: "Height",
      type: "number",
      role: "value",
    },
    label: {
      name: "label",
      displayName: "Label",
      type: "string",
      role: "value",
    },
    fontScale: {
      name: "fontScale",
      displayName: "Font Scale",
      type: "number",
      role: "value",
    },
    shape: {
      name: "shape",
      displayName: "Shape",
      type: "enum",
      get typeDef() { return domain.enums.SeatMapAnnotationShape },
      role: "value",
    },
  },
}
export const SeatMapDto = domain.types.SeatMapDto = {
  name: "SeatMapDto",
  displayName: "Seat Map Dto",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    name: {
      name: "name",
      displayName: "Name",
      type: "string",
      role: "value",
    },
    venueId: {
      name: "venueId",
      displayName: "Venue Id",
      type: "number",
      role: "value",
    },
    venueName: {
      name: "venueName",
      displayName: "Venue Name",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    sections: {
      name: "sections",
      displayName: "Sections",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.SeatMapSectionDto as ObjectType) },
      },
      role: "value",
    },
    annotations: {
      name: "annotations",
      displayName: "Annotations",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.SeatMapAnnotationDto as ObjectType) },
      },
      role: "value",
    },
  },
}
export const SeatMapSeatDto = domain.types.SeatMapSeatDto = {
  name: "SeatMapSeatDto",
  displayName: "Seat Map Seat Dto",
  type: "object",
  props: {
    seatNumber: {
      name: "seatNumber",
      displayName: "Seat Number",
      type: "string",
      role: "value",
    },
    x: {
      name: "x",
      displayName: "X",
      type: "number",
      role: "value",
    },
    y: {
      name: "y",
      displayName: "Y",
      type: "number",
      role: "value",
    },
  },
}
export const SeatMapSection = domain.types.SeatMapSection = {
  name: "SeatMapSection",
  displayName: "Seat Map Section",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    seatMapId: {
      name: "seatMapId",
      displayName: "Seat Map Id",
      type: "number",
      role: "value",
    },
    seatMap: {
      name: "seatMap",
      displayName: "Seat Map",
      type: "object",
      get typeDef() { return (domain.types.SeatMap as ObjectType) },
      role: "value",
    },
    name: {
      name: "name",
      displayName: "Name",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Name is required.",
      }
    },
    color: {
      name: "color",
      displayName: "Color",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Color is required.",
        maxLength: val => !val || val.length <= 7 || "Color may not be more than 7 characters.",
      }
    },
  },
}
export const SeatMapSectionDto = domain.types.SeatMapSectionDto = {
  name: "SeatMapSectionDto",
  displayName: "Seat Map Section Dto",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    name: {
      name: "name",
      displayName: "Name",
      type: "string",
      role: "value",
    },
    color: {
      name: "color",
      displayName: "Color",
      type: "string",
      role: "value",
    },
    seats: {
      name: "seats",
      displayName: "Seats",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.SeatMapSeatDto as ObjectType) },
      },
      role: "value",
    },
    prices: {
      name: "prices",
      displayName: "Prices",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.EventPriceDto as ObjectType) },
      },
      role: "value",
    },
  },
}
export const Tenant = domain.types.Tenant = {
  name: "Tenant",
  displayName: "Tenant",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    tenantId: {
      name: "tenantId",
      displayName: "Tenant Id",
      type: "number",
      role: "value",
    },
    name: {
      name: "name",
      displayName: "Name",
      description: "The name of the tenant, shown throughout the application to both patrons and logged-in users.",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Name is required.",
      }
    },
    shortCode: {
      name: "shortCode",
      displayName: "Short Code",
      description: "A short code that uniquely identifies the tenant. Depending on Payment Gateway settings, this may be prepended to invoice numbers.",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Short Code is required.",
        minLength: val => !val || val.length >= 3 || "Short Code must be at least 3 characters.",
        maxLength: val => !val || val.length <= 3 || "Short Code may not be more than 3 characters.",
      }
    },
    supportEmail: {
      name: "supportEmail",
      displayName: "Support Email",
      description: "An email address that patrons may message to receive customer support.",
      type: "string",
      role: "value",
      rules: {
        email: val => !val || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<> ()\[\]\\.,;:\s@"]+)*)|(".+ "))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val.trim()) || "Support Email must be a valid email address.",
      }
    },
    supportPhone: {
      name: "supportPhone",
      displayName: "Support Phone",
      description: "A phone number that patrons may call to receive customer support.",
      type: "string",
      role: "value",
    },
    emailSenderAddress: {
      name: "emailSenderAddress",
      displayName: "Email Sender Address",
      description: "The 'From' address + domain when sending email with Mailgun. Required to send purchase confirmations and other essential communication.",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      rules: {
        email: val => !val || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<> ()\[\]\\.,;:\s@"]+)*)|(".+ "))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val.trim()) || "Email Sender Address must be a valid email address.",
      }
    },
    mailgunApiKey: {
      name: "mailgunApiKey",
      displayName: "Mailgun Api Key",
      description: "A Mailgun API key that corresponds to the domain used in Email Sender Address. Required to send purchase confirmations and other essential communication.",
      type: "string",
      subtype: "password",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    twilioMessageServiceSid: {
      name: "twilioMessageServiceSid",
      displayName: "Twilio Message Service Sid",
      type: "string",
      subtype: "password",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    marketingEnabled: {
      name: "marketingEnabled",
      displayName: "Marketing Enabled",
      description: "If enabled, patrons will be asked during their purchase if they want to sign up for marketing communications. Note that the actual sending of marketing communications is an orthogonal process not handled by this application in any way.",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    textMessagingEnabled: {
      name: "textMessagingEnabled",
      displayName: "Text Messaging Enabled",
      description: "If enabled, tenant can send text messages to all customers who subscribed to the service",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    metaPixelsEnabled: {
      name: "metaPixelsEnabled",
      displayName: "Meta Pixels Enabled",
      description: "If enabled, Meta Pixels will be running in the front-end web pages",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    bankStatementNotificationEnabled: {
      name: "bankStatementNotificationEnabled",
      displayName: "Bank Statement Notification Enabled",
      description: "If enabled, tenant will see the Ticket Goat disclaimer message during checkout",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    metaPixelAppId: {
      name: "metaPixelAppId",
      displayName: "Meta Pixel App Id",
      description: "A Meta Pixel Application ID is unique key for each tenant and can be managed over Meta Pixel admin console",
      type: "string",
      subtype: "password",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    paymentGatewayId: {
      name: "paymentGatewayId",
      displayName: "Payment Gateway Id",
      type: "number",
      role: "value",
    },
    paymentGateway: {
      name: "paymentGateway",
      displayName: "Payment Gateway",
      type: "object",
      get typeDef() { return (domain.types.PaymentGateway as ObjectType) },
      role: "value",
    },
    refundableFeePercentage: {
      name: "refundableFeePercentage",
      displayName: "Refundable Fee Percentage",
      description: "The cost of the purchase protection option, as a percentage of the total of the Ticket Prices for the purchase.",
      type: "number",
      role: "value",
      hidden: 1 as HiddenAreas,
      rules: {
        min: val => val == null || val >= 0 || "Refundable Fee Percentage must be at least 0.",
        max: val => val == null || val <= 100 || "Refundable Fee Percentage may not be more than 100.",
      }
    },
    convenienceFeePercentage: {
      name: "convenienceFeePercentage",
      displayName: "Convenience Fee Percentage",
      description: "A percent surcharge applied to the grand total (before taxes) of all purchases.",
      type: "number",
      role: "value",
      hidden: 1 as HiddenAreas,
      rules: {
        min: val => val == null || val >= 0 || "Convenience Fee Percentage must be at least 0.",
        max: val => val == null || val <= 100 || "Convenience Fee Percentage may not be more than 100.",
      }
    },
    serviceFeeAmount: {
      name: "serviceFeeAmount",
      displayName: "Service Fee Amount",
      description: "A surcharge, in dollars, applied for each ticket in a purchase. If blank, Service Fee will be customizable per Ticket Type and agents will be able to waive this fee on a per-purchase basis.",
      type: "number",
      role: "value",
      hidden: 1 as HiddenAreas,
      rules: {
        min: val => val == null || val >= 0 || "Service Fee Amount must be at least 0.",
      }
    },
    showImageOnListView: {
      name: "showImageOnListView",
      displayName: "Show Image On List View",
      description: "This will allow adding images to the event list view.",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    isAllowFreeEvent: {
      name: "isAllowFreeEvent",
      displayName: "Is Allow Free Event",
      description: "This will allow tenant to create non-paid events. Provide an extra step while creating an event.",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    showRemainingSeats: {
      name: "showRemainingSeats",
      displayName: "Show Remaining Seats",
      description: "Enabling this will show remaining seats for all active events.",
      type: "boolean",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    dietaryRestrictionNotificationEmailAddress: {
      name: "dietaryRestrictionNotificationEmailAddress",
      displayName: "Dietary Restriction Notification Email Address",
      description: "The email to notify when a purchase with dietary restrictions is made.",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      rules: {
        email: val => !val || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<> ()\[\]\\.,;:\s@"]+)*)|(".+ "))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val.trim()) || "Dietary Restriction Notification Email Address must be a valid email address.",
      }
    },
    colorPrimary: {
      name: "colorPrimary",
      displayName: "Color Primary",
      type: "string",
      subtype: "color",
      role: "value",
      rules: {
        pattern: val => !val || /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(val) || "Color Primary does not match expected format.",
      }
    },
    colorSecondary: {
      name: "colorSecondary",
      displayName: "Color Secondary",
      type: "string",
      subtype: "color",
      role: "value",
      rules: {
        pattern: val => !val || /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(val) || "Color Secondary does not match expected format.",
      }
    },
    colorAccent: {
      name: "colorAccent",
      displayName: "Color Accent",
      type: "string",
      subtype: "color",
      role: "value",
      rules: {
        pattern: val => !val || /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(val) || "Color Accent does not match expected format.",
      }
    },
    hosts: {
      name: "hosts",
      displayName: "Hosts",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.TenantHost as ObjectType) },
      },
      role: "value",
    },
    faviconUrl: {
      name: "faviconUrl",
      displayName: "Favicon",
      type: "string",
      subtype: "url-image",
      role: "value",
      dontSerialize: true,
    },
    fullLogoUrl: {
      name: "fullLogoUrl",
      displayName: "Full Logo",
      type: "string",
      subtype: "url-image",
      role: "value",
      dontSerialize: true,
    },
    squareLogoUrl: {
      name: "squareLogoUrl",
      displayName: "Square Logo",
      type: "string",
      subtype: "url-image",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const TenantHost = domain.types.TenantHost = {
  name: "TenantHost",
  displayName: "Tenant Host",
  type: "object",
  props: {
    tenantHostId: {
      name: "tenantHostId",
      displayName: "Tenant Host Id",
      type: "number",
      role: "value",
    },
    tenantId: {
      name: "tenantId",
      displayName: "Tenant Id",
      type: "number",
      role: "value",
    },
    tenant: {
      name: "tenant",
      displayName: "Tenant",
      type: "object",
      get typeDef() { return (domain.types.Tenant as ObjectType) },
      role: "value",
    },
    host: {
      name: "host",
      displayName: "Host",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Host is required.",
      }
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const TenantMembership = domain.types.TenantMembership = {
  name: "TenantMembership",
  displayName: "Tenant Membership",
  type: "object",
  props: {
    tenantMembershipId: {
      name: "tenantMembershipId",
      displayName: "Tenant Membership Id",
      type: "number",
      role: "value",
    },
    tenantId: {
      name: "tenantId",
      displayName: "Tenant Id",
      type: "number",
      role: "value",
    },
    tenant: {
      name: "tenant",
      displayName: "Tenant",
      type: "object",
      get typeDef() { return (domain.types.Tenant as ObjectType) },
      role: "value",
    },
    userId: {
      name: "userId",
      displayName: "User Id",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "User Id is required.",
      }
    },
    user: {
      name: "user",
      displayName: "User",
      type: "object",
      get typeDef() { return (domain.types.AppUser as ObjectType) },
      role: "value",
    },
    isEmailSubscribed: {
      name: "isEmailSubscribed",
      displayName: "Is Email Subscribed",
      type: "boolean",
      role: "value",
    },
    isPhoneSubscribed: {
      name: "isPhoneSubscribed",
      displayName: "Is Phone Subscribed",
      type: "boolean",
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const TicketPurchaseAddon = domain.types.TicketPurchaseAddon = {
  name: "TicketPurchaseAddon",
  displayName: "Ticket Purchase Addon",
  get displayProp() { return this.props.name }, 
  type: "object",
  props: {
    addonId: {
      name: "addonId",
      displayName: "Addon Id",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    addonItemConfigurationId: {
      name: "addonItemConfigurationId",
      displayName: "Addon Item Configuration Id",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    name: {
      name: "name",
      displayName: "Name",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    marketingDescription: {
      name: "marketingDescription",
      displayName: "Marketing Description",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    price: {
      name: "price",
      displayName: "Price",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    convenienceFee: {
      name: "convenienceFee",
      displayName: "Convenience Fee",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    refundedPrice: {
      name: "refundedPrice",
      displayName: "Refunded Price",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    totalValue: {
      name: "totalValue",
      displayName: "Total Value",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    salesTax: {
      name: "salesTax",
      displayName: "Sales Tax",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    isPerTicket: {
      name: "isPerTicket",
      displayName: "Is Per Ticket",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    status: {
      name: "status",
      displayName: "Status",
      type: "enum",
      get typeDef() { return domain.enums.AddonStatus },
      role: "value",
      dontSerialize: true,
    },
    isPrintable: {
      name: "isPrintable",
      displayName: "Is Printable",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    relatedPurchase: {
      name: "relatedPurchase",
      displayName: "Related Purchase",
      type: "object",
      get typeDef() { return (domain.types.TicketPurchaseRelatedPurchase as ObjectType) },
      role: "value",
      dontSerialize: true,
    },
  },
}
export const TicketPurchaseDietaryRestriction = domain.types.TicketPurchaseDietaryRestriction = {
  name: "TicketPurchaseDietaryRestriction",
  displayName: "Ticket Purchase Dietary Restriction",
  type: "object",
  props: {
    allergen: {
      name: "allergen",
      displayName: "Allergen",
      type: "string",
      role: "value",
    },
    guestQuantity: {
      name: "guestQuantity",
      displayName: "Guest Quantity",
      type: "number",
      role: "value",
    },
    comment: {
      name: "comment",
      displayName: "Comment",
      type: "string",
      role: "value",
    },
    canComment: {
      name: "canComment",
      displayName: "Can Comment",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const TicketPurchaseDiscount = domain.types.TicketPurchaseDiscount = {
  name: "TicketPurchaseDiscount",
  displayName: "Ticket Purchase Discount",
  type: "object",
  props: {
    discountId: {
      name: "discountId",
      displayName: "Discount Id",
      type: "number",
      role: "value",
    },
    description: {
      name: "description",
      displayName: "Description",
      type: "string",
      role: "value",
    },
    amount: {
      name: "amount",
      displayName: "Amount",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    error: {
      name: "error",
      displayName: "Error",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const TicketPurchasePayment = domain.types.TicketPurchasePayment = {
  name: "TicketPurchasePayment",
  displayName: "Ticket Purchase Payment",
  type: "object",
  props: {
    paymentId: {
      name: "paymentId",
      displayName: "Payment Id",
      type: "number",
      role: "value",
    },
    paymentTypeName: {
      name: "paymentTypeName",
      displayName: "Payment Type Name",
      type: "string",
      role: "value",
    },
    amount: {
      name: "amount",
      displayName: "Amount",
      type: "number",
      role: "value",
    },
    description: {
      name: "description",
      displayName: "Description",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    paymentKind: {
      name: "paymentKind",
      displayName: "Payment Kind",
      type: "enum",
      get typeDef() { return domain.enums.PaymentKind },
      role: "value",
      dontSerialize: true,
    },
    requiresCcInput: {
      name: "requiresCcInput",
      displayName: "Requires Cc Input",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    canChangeAmount: {
      name: "canChangeAmount",
      displayName: "Can Change Amount",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    canRemove: {
      name: "canRemove",
      displayName: "Can Remove",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    isRefund: {
      name: "isRefund",
      displayName: "Is Refund",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    allowsNegativeSum: {
      name: "allowsNegativeSum",
      displayName: "Allows Negative Sum",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    allowsPositiveSum: {
      name: "allowsPositiveSum",
      displayName: "Allows Positive Sum",
      type: "boolean",
      role: "value",
      dontSerialize: true,
    },
    maxRefundableAmount: {
      name: "maxRefundableAmount",
      displayName: "Max Refundable Amount",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    relatedPurchases: {
      name: "relatedPurchases",
      displayName: "Related Purchases",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.TicketPurchaseRelatedPurchase as ObjectType) },
      },
      role: "value",
      dontSerialize: true,
    },
  },
}
export const TicketPurchaseRelatedPurchase = domain.types.TicketPurchaseRelatedPurchase = {
  name: "TicketPurchaseRelatedPurchase",
  displayName: "Ticket Purchase Related Purchase",
  get displayProp() { return this.props.description }, 
  type: "object",
  props: {
    publicId: {
      name: "publicId",
      displayName: "Public Id",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    description: {
      name: "description",
      displayName: "Description",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    ticketIds: {
      name: "ticketIds",
      displayName: "Ticket Ids",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "number",
      },
      role: "value",
      dontSerialize: true,
    },
    addonIds: {
      name: "addonIds",
      displayName: "Addon Ids",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "number",
      },
      role: "value",
      dontSerialize: true,
    },
  },
}
export const TicketPurchaseTicket = domain.types.TicketPurchaseTicket = {
  name: "TicketPurchaseTicket",
  displayName: "Ticket Purchase Ticket",
  type: "object",
  props: {
    ticketId: {
      name: "ticketId",
      displayName: "Ticket Id",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    eventPriceId: {
      name: "eventPriceId",
      displayName: "Event Price Id",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    seatNumber: {
      name: "seatNumber",
      displayName: "Seat Number",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    description: {
      name: "description",
      displayName: "Description",
      type: "string",
      role: "value",
      dontSerialize: true,
    },
    price: {
      name: "price",
      displayName: "Price",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    fees: {
      name: "fees",
      displayName: "Fees",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    convenienceFee: {
      name: "convenienceFee",
      displayName: "Convenience Fee",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    salesTax: {
      name: "salesTax",
      displayName: "Sales Tax",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    refundableFee: {
      name: "refundableFee",
      displayName: "Refundable Fee",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    discountTotal: {
      name: "discountTotal",
      displayName: "Discount Total",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    subtotalWithFees: {
      name: "subtotalWithFees",
      displayName: "Subtotal With Fees",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    addons: {
      name: "addons",
      displayName: "Addons",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    totalValue: {
      name: "totalValue",
      displayName: "Total Value",
      type: "number",
      role: "value",
      dontSerialize: true,
    },
    status: {
      name: "status",
      displayName: "Status",
      type: "enum",
      get typeDef() { return domain.enums.TicketStatus },
      role: "value",
      dontSerialize: true,
    },
    relatedPurchase: {
      name: "relatedPurchase",
      displayName: "Related Purchase",
      type: "object",
      get typeDef() { return (domain.types.TicketPurchaseRelatedPurchase as ObjectType) },
      role: "value",
      dontSerialize: true,
    },
  },
}
export const TicketTemplate = domain.types.TicketTemplate = {
  name: "TicketTemplate",
  displayName: "Ticket Template",
  get displayProp() { return this.props.description }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    description: {
      name: "description",
      displayName: "Description",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Description is required.",
      }
    },
    htmlContent: {
      name: "htmlContent",
      displayName: "Html Content",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    pageHeightInches: {
      name: "pageHeightInches",
      displayName: "Page Height (inches)",
      type: "number",
      role: "value",
    },
    pageWidthInches: {
      name: "pageWidthInches",
      displayName: "Page Width (inches)",
      type: "number",
      role: "value",
    },
    companyName: {
      name: "companyName",
      displayName: "Company Name",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Company Name is required.",
      }
    },
    events: {
      name: "events",
      displayName: "Events",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.Event as ObjectType) },
      },
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const Venue = domain.types.Venue = {
  name: "Venue",
  displayName: "Venue",
  get displayProp() { return this.props.displayName }, 
  type: "object",
  props: {
    id: {
      name: "id",
      displayName: "Id",
      type: "number",
      role: "value",
    },
    displayName: {
      name: "displayName",
      displayName: "Display Name",
      type: "string",
      role: "value",
      rules: {
        required: val => (val != null && val !== '') || "Display Name is required.",
      }
    },
    boardingLabel: {
      name: "boardingLabel",
      displayName: "Early arrival label",
      description: "The type of early arrival that can occur for events in this venue. This verbiage will be displayed next to event descriptions and is available in ticket/email templates.",
      type: "enum",
      get typeDef() { return domain.enums.BoardingLabel },
      role: "value",
    },
    salesTaxPercentage: {
      name: "salesTaxPercentage",
      displayName: "Sales Tax Percentage",
      description: "The tax percentage applied to the grand total of the purchase.",
      type: "number",
      role: "value",
      rules: {
        required: val => val != null || "Sales Tax Percentage is required.",
        min: val => val == null || val >= 0 || "Sales Tax Percentage must be at least 0.",
        max: val => val == null || val <= 100 || "Sales Tax Percentage may not be more than 100.",
      }
    },
    events: {
      name: "events",
      displayName: "Events",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.Event as ObjectType) },
      },
      role: "value",
    },
    seatMaps: {
      name: "seatMaps",
      displayName: "Seat Maps",
      type: "collection",
      itemType: {
        name: "$collectionItem",
        displayName: "",
        role: "value",
        type: "object",
        get typeDef() { return (domain.types.SeatMap as ObjectType) },
      },
      role: "value",
      hidden: 1 as HiddenAreas,
    },
    address: {
      name: "address",
      displayName: "Address",
      type: "string",
      role: "value",
    },
    state: {
      name: "state",
      displayName: "State",
      type: "string",
      role: "value",
    },
    zip: {
      name: "zip",
      displayName: "Zip",
      type: "string",
      role: "value",
    },
    country: {
      name: "country",
      displayName: "Country",
      type: "string",
      role: "value",
    },
    creationUserName: {
      name: "creationUserName",
      displayName: "Created By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    creationDate: {
      name: "creationDate",
      displayName: "Created On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeUserName: {
      name: "lastChangeUserName",
      displayName: "Modified By",
      type: "string",
      role: "value",
      hidden: 1 as HiddenAreas,
      dontSerialize: true,
    },
    lastChangeDate: {
      name: "lastChangeDate",
      displayName: "Modified On",
      type: "date",
      dateKind: "datetime",
      role: "value",
      dontSerialize: true,
    },
  },
}
export const PublicPurchaseService = domain.services.PublicPurchaseService = {
  name: "PublicPurchaseService",
  displayName: "Public Purchase Service",
  type: "service",
  controllerRoute: "PublicPurchaseService",
  methods: {
    initiatePurchase: {
      name: "initiatePurchase",
      displayName: "Initiate Purchase",
      transportType: "item",
      httpMethod: "POST",
      params: {
        eventDateId: {
          name: "eventDateId",
          displayName: "Event Date Id",
          type: "number",
          role: "value",
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "model",
        get typeDef() { return (domain.types.TicketPurchaseDto as ModelType) },
        role: "value",
      },
    },
    getSeating: {
      name: "getSeating",
      displayName: "Get Seating",
      transportType: "item",
      httpMethod: "POST",
      params: {
        publicId: {
          name: "publicId",
          displayName: "Public Id",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Public Id is required.",
          }
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "object",
        get typeDef() { return (domain.types.EventDateSeatingInfo as ObjectType) },
        role: "value",
      },
    },
    setTicketQuantity: {
      name: "setTicketQuantity",
      displayName: "Set Ticket Quantity",
      transportType: "item",
      httpMethod: "POST",
      params: {
        publicId: {
          name: "publicId",
          displayName: "Public Id",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Public Id is required.",
          }
        },
        eventPriceId: {
          name: "eventPriceId",
          displayName: "Event Price Id",
          type: "number",
          role: "value",
        },
        quantity: {
          name: "quantity",
          displayName: "Quantity",
          type: "number",
          role: "value",
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "model",
        get typeDef() { return (domain.types.TicketPurchaseDto as ModelType) },
        role: "value",
      },
    },
    addTicket: {
      name: "addTicket",
      displayName: "Add Ticket",
      transportType: "item",
      httpMethod: "POST",
      params: {
        publicId: {
          name: "publicId",
          displayName: "Public Id",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Public Id is required.",
          }
        },
        eventPriceId: {
          name: "eventPriceId",
          displayName: "Event Price Id",
          type: "number",
          role: "value",
        },
        seat: {
          name: "seat",
          displayName: "Seat",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Seat is required.",
          }
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "model",
        get typeDef() { return (domain.types.TicketPurchaseDto as ModelType) },
        role: "value",
      },
    },
    removeTicket: {
      name: "removeTicket",
      displayName: "Remove Ticket",
      transportType: "item",
      httpMethod: "POST",
      params: {
        publicId: {
          name: "publicId",
          displayName: "Public Id",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Public Id is required.",
          }
        },
        ticketId: {
          name: "ticketId",
          displayName: "Ticket Id",
          type: "number",
          role: "value",
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "model",
        get typeDef() { return (domain.types.TicketPurchaseDto as ModelType) },
        role: "value",
      },
    },
    setAddonQuantity: {
      name: "setAddonQuantity",
      displayName: "Set Addon Quantity",
      transportType: "item",
      httpMethod: "POST",
      params: {
        publicId: {
          name: "publicId",
          displayName: "Public Id",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Public Id is required.",
          }
        },
        addonConfigId: {
          name: "addonConfigId",
          displayName: "Addon Config Id",
          type: "number",
          role: "value",
        },
        quantity: {
          name: "quantity",
          displayName: "Quantity",
          type: "number",
          role: "value",
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "model",
        get typeDef() { return (domain.types.TicketPurchaseDto as ModelType) },
        role: "value",
      },
    },
    setDietaryRestriction: {
      name: "setDietaryRestriction",
      displayName: "Set Dietary Restriction",
      transportType: "item",
      httpMethod: "POST",
      params: {
        publicId: {
          name: "publicId",
          displayName: "Public Id",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Public Id is required.",
          }
        },
        allergen: {
          name: "allergen",
          displayName: "Allergen",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Allergen is required.",
          }
        },
        quantity: {
          name: "quantity",
          displayName: "Quantity",
          type: "number",
          role: "value",
        },
        comment: {
          name: "comment",
          displayName: "Comment",
          type: "string",
          role: "value",
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "model",
        get typeDef() { return (domain.types.TicketPurchaseDto as ModelType) },
        role: "value",
      },
    },
    removeDiscount: {
      name: "removeDiscount",
      displayName: "Remove Discount",
      transportType: "item",
      httpMethod: "POST",
      params: {
        publicId: {
          name: "publicId",
          displayName: "Public Id",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Public Id is required.",
          }
        },
        discountId: {
          name: "discountId",
          displayName: "Discount Id",
          type: "number",
          role: "value",
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "model",
        get typeDef() { return (domain.types.TicketPurchaseDto as ModelType) },
        role: "value",
      },
    },
    applyDiscountCode: {
      name: "applyDiscountCode",
      displayName: "Apply Discount Code",
      transportType: "item",
      httpMethod: "POST",
      params: {
        publicId: {
          name: "publicId",
          displayName: "Public Id",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Public Id is required.",
          }
        },
        discountCode: {
          name: "discountCode",
          displayName: "Discount Code",
          type: "string",
          role: "value",
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "model",
        get typeDef() { return (domain.types.TicketPurchaseDto as ModelType) },
        role: "value",
      },
    },
    completePublicCardPayment: {
      name: "completePublicCardPayment",
      displayName: "Complete Public Card Payment",
      transportType: "item",
      httpMethod: "POST",
      params: {
        publicId: {
          name: "publicId",
          displayName: "Public Id",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Public Id is required.",
          }
        },
        cardDetails: {
          name: "cardDetails",
          displayName: "Card Details",
          type: "object",
          get typeDef() { return (domain.types.CreditCardDetails as ObjectType) },
          role: "value",
          rules: {
            required: val => val != null || "Card Details is required.",
          }
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "string",
        role: "value",
      },
    },
    getPostalCodeInfo: {
      name: "getPostalCodeInfo",
      displayName: "Get Postal Code Info",
      transportType: "item",
      httpMethod: "POST",
      params: {
        postalCode: {
          name: "postalCode",
          displayName: "Postal Code",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Postal Code is required.",
          }
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "object",
        get typeDef() { return (domain.types.PostalCodeInfo as ObjectType) },
        role: "value",
      },
    },
    markTicketPurchaseHoneyPotSpam: {
      name: "markTicketPurchaseHoneyPotSpam",
      displayName: "Mark Ticket Purchase Honey Pot Spam",
      transportType: "item",
      httpMethod: "POST",
      params: {
        publicId: {
          name: "publicId",
          displayName: "Public Id",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Public Id is required.",
          }
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "void",
        role: "value",
      },
    },
  },
}
export const SignInService = domain.services.SignInService = {
  name: "SignInService",
  displayName: "Sign In Service",
  type: "service",
  controllerRoute: "SignInService",
  methods: {
    signIn: {
      name: "signIn",
      displayName: "Sign In",
      transportType: "item",
      httpMethod: "POST",
      params: {
        userName: {
          name: "userName",
          displayName: "User Name",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "User Name is required.",
          }
        },
        password: {
          name: "password",
          displayName: "Password",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Password is required.",
          }
        },
        rememberMe: {
          name: "rememberMe",
          displayName: "Remember Me",
          type: "boolean",
          role: "value",
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "void",
        role: "value",
      },
    },
    requestPasswordReset: {
      name: "requestPasswordReset",
      displayName: "Request Password Reset",
      transportType: "item",
      httpMethod: "POST",
      params: {
        userName: {
          name: "userName",
          displayName: "User Name",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "User Name is required.",
          }
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "void",
        role: "value",
      },
    },
    resetPassword: {
      name: "resetPassword",
      displayName: "Reset Password",
      transportType: "item",
      httpMethod: "POST",
      params: {
        userName: {
          name: "userName",
          displayName: "User Name",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "User Name is required.",
          }
        },
        code: {
          name: "code",
          displayName: "Code",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Code is required.",
          }
        },
        newPassword: {
          name: "newPassword",
          displayName: "New Password",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "New Password is required.",
          }
        },
        confirmNewPassword: {
          name: "confirmNewPassword",
          displayName: "Confirm New Password",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Confirm New Password is required.",
          }
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "void",
        role: "value",
      },
    },
    setupAccount: {
      name: "setupAccount",
      displayName: "Setup Account",
      transportType: "item",
      httpMethod: "POST",
      params: {
        userName: {
          name: "userName",
          displayName: "User Name",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "User Name is required.",
          }
        },
        code: {
          name: "code",
          displayName: "Code",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Code is required.",
          }
        },
        newPassword: {
          name: "newPassword",
          displayName: "New Password",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "New Password is required.",
          }
        },
        confirmNewPassword: {
          name: "confirmNewPassword",
          displayName: "Confirm New Password",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Confirm New Password is required.",
          }
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "void",
        role: "value",
      },
    },
    confirmEmail: {
      name: "confirmEmail",
      displayName: "Confirm Email",
      transportType: "item",
      httpMethod: "POST",
      params: {
        userId: {
          name: "userId",
          displayName: "User Id",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "User Id is required.",
          }
        },
        code: {
          name: "code",
          displayName: "Code",
          type: "string",
          role: "value",
          rules: {
            required: val => (val != null && val !== '') || "Code is required.",
          }
        },
        newEmail: {
          name: "newEmail",
          displayName: "New Email",
          type: "string",
          role: "value",
        },
      },
      return: {
        name: "$return",
        displayName: "Result",
        type: "void",
        role: "value",
      },
    },
  },
}

interface AppDomain extends Domain {
  enums: {
    AddonItemType: typeof AddonItemType
    AddonStatus: typeof AddonStatus
    BoardingLabel: typeof BoardingLabel
    DiscountType: typeof DiscountType
    EmailType: typeof EmailType
    PaymentKind: typeof PaymentKind
    PurchaseStatus: typeof PurchaseStatus
    PurchaseType: typeof PurchaseType
    RefundableState: typeof RefundableState
    SeatMapAnnotationShape: typeof SeatMapAnnotationShape
    TicketStatus: typeof TicketStatus
  }
  types: {
    AddonItemConfiguration: typeof AddonItemConfiguration
    AddonItemDefinition: typeof AddonItemDefinition
    AppRole: typeof AppRole
    AppUser: typeof AppUser
    AppUserAllTenantRole: typeof AppUserAllTenantRole
    AppUserRole: typeof AppUserRole
    Consignee: typeof Consignee
    ConsigneeCommission: typeof ConsigneeCommission
    ConsigneeDiscountDefinition: typeof ConsigneeDiscountDefinition
    ConsigneeRep: typeof ConsigneeRep
    CreditCardDetails: typeof CreditCardDetails
    DiscountDefinition: typeof DiscountDefinition
    DiscountDefinitionConfiguration: typeof DiscountDefinitionConfiguration
    EmailTemplate: typeof EmailTemplate
    Event: typeof Event
    EventAddonDto: typeof EventAddonDto
    EventAddonItemDefinition: typeof EventAddonItemDefinition
    EventDate: typeof EventDate
    EventDateDiscountDefinition: typeof EventDateDiscountDefinition
    EventDateDto: typeof EventDateDto
    EventDatePriceConfig: typeof EventDatePriceConfig
    EventDateSeatingInfo: typeof EventDateSeatingInfo
    EventDateSeatingRestrictionInfo: typeof EventDateSeatingRestrictionInfo
    EventDateSeatRestriction: typeof EventDateSeatRestriction
    EventDiscountDefinition: typeof EventDiscountDefinition
    EventDto: typeof EventDto
    EventEmailTemplate: typeof EventEmailTemplate
    EventImage: typeof EventImage
    EventPrice: typeof EventPrice
    EventPriceDto: typeof EventPriceDto
    EventPriceRevenueDistribution: typeof EventPriceRevenueDistribution
    EventSection: typeof EventSection
    EventSectionPrice: typeof EventSectionPrice
    EventSuggestedEvent: typeof EventSuggestedEvent
    EventType: typeof EventType
    EventTypeDto: typeof EventTypeDto
    PaymentGateway: typeof PaymentGateway
    PostalCodeInfo: typeof PostalCodeInfo
    RevenueCategory: typeof RevenueCategory
    SeatMap: typeof SeatMap
    SeatMapAnnotationDto: typeof SeatMapAnnotationDto
    SeatMapDto: typeof SeatMapDto
    SeatMapSeatDto: typeof SeatMapSeatDto
    SeatMapSection: typeof SeatMapSection
    SeatMapSectionDto: typeof SeatMapSectionDto
    Tenant: typeof Tenant
    TenantHost: typeof TenantHost
    TenantMembership: typeof TenantMembership
    TicketPurchaseAddon: typeof TicketPurchaseAddon
    TicketPurchaseDietaryRestriction: typeof TicketPurchaseDietaryRestriction
    TicketPurchaseDiscount: typeof TicketPurchaseDiscount
    TicketPurchaseDto: typeof TicketPurchaseDto
    TicketPurchasePayment: typeof TicketPurchasePayment
    TicketPurchaseRelatedPurchase: typeof TicketPurchaseRelatedPurchase
    TicketPurchaseTicket: typeof TicketPurchaseTicket
    TicketTemplate: typeof TicketTemplate
    Venue: typeof Venue
  }
  services: {
    PublicPurchaseService: typeof PublicPurchaseService
    SignInService: typeof SignInService
  }
}

solidify(domain)

export default domain as unknown as AppDomain
